export enum VisualType {
  Primary = 'primary',
  Secondary = 'secondary',
  Discover = 'discover',
  Anchor = 'anchor',
}

export enum VisualVariant {
  Light = 'light',
  Dark = 'dark',
  Darker = 'darker',
  Active = 'active',
}
