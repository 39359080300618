const LAYOUT_BREAKPOINTS = {
  xs: 0,
  sm: 375,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1440,
};

const KEYBOARD_KEYS = {
  enter: 'Enter',
};

enum BANNER_BACKGROUND_COLOR {
  pink = 'pink',
  grey = 'grey',
}

const SEARCH_RESULTS = {
  matchLevelNone: 'none',
};

const gridColumnsCount = 12;

export {
  BANNER_BACKGROUND_COLOR,
  gridColumnsCount,
  KEYBOARD_KEYS,
  LAYOUT_BREAKPOINTS,
  SEARCH_RESULTS,
};
